.eyecatcher {
    width: 100%;
    height: 100%;
    background: url("/images/backgroundpaper.png");
    padding: 0 ($grid-gutter-width / 2);
    @include media-breakpoint-up(sm) {
        padding: 0 ($grid-gutter-width * 2);
    }
    &.large {
       
        .owl-carousel {
            .owl-stage-outer {
                .owl-stage {
                    .owl-item {
                        .item {
                            border-radius: 12px;
                        }
                    }
                }
            }
            .owl-dots {
                right: auto;
                bottom: 40px;
                left: 50px;
            }
        }
    }
    .owl-carousel {
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .item {
                        border-radius: 0 0 12px 12px;

                        .owl-container {
                            .owl-caption {
                                position: absolute;
                                bottom: 5px;
                                left: 50px;
                                right: 0;
                                max-width: 1028px;
                                text-align: left;
                                .owl-title {
                                    @extend h1;
                                    color: white;
                                    text-shadow: none;
                                }
                                .owl-subtitle {
                                    font-family: $font-family-yeseva;
                                    font-size: $h3-font-size;
                                    font-weight: $font-weight-regular;
                                    text-shadow: none;
                                    margin-bottom: $grid-gutter-width * 1.33;
                                    @include media-breakpoint-up(sm) {
                                        margin-bottom: 0;
                                        margin-left: $grid-gutter-width * 3;
                                    }
                                  
                                }
                            }
                        }
                    }
                }
            }
        }
        .owl-nav {
            display: none;
        }
        .owl-dots {
            right: 50px;
            bottom: 40px;
            left: auto;

            .owl-dot {
                &.active {
                    span {
                        background-color: white;
                    }
                }
            }
        }
    }
    .page-block.logo {
        padding-top: 50px;
        padding-bottom: 100px;
        display: block;
        opacity: 0;

        @include media-breakpoint-up(sm) {
            opacity: 1;
            padding-bottom: 250px;
        }
        img {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            z-index: 11;
            display: block;
            width: 316px;
            height: 150px;

            @include media-breakpoint-up(xxl) {
                width: 445px;
                height: 212px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 230px;
            z-index: 1;
        }
    }
}

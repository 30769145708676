.collection.grid {
	.grid-items {
		&:not(:last-of-type) {
			margin-bottom: $grid-gutter-width * 2.5;

			@include media-breakpoint-up(sm) {
				margin-bottom: $grid-gutter-width * 4;
			}
		}

		.item {
			@include make-col(12);
			margin-bottom: $grid-gutter-width;

			@include media-breakpoint-up(sm) {
				margin-bottom: $grid-gutter-width * 4;
			}

			&:last-of-type {
				margin-bottom: $grid-gutter-width;
				@include media-breakpoint-up(sm) {
					margin-bottom: $grid-gutter-width;
				}
			}

			&:nth-child(odd) {
				.card {
					&-image {
						order: 1;

						@include media-breakpoint-up(sm) {
							order: 2;
						}
					}

					&-body {
						order: 2;

						@include media-breakpoint-up(sm) {
							order: 1;
							padding-right: 1.5rem !important;
						}
						@include media-breakpoint-up(lg) {
							padding-right: 3rem !important;
						}
					}
				}
			}

			&:nth-child(even) {
				.card {
					&-body {
						@include media-breakpoint-up(sm) {
							padding-left: 1.5rem !important;
						}
						@include media-breakpoint-up(lg) {
							padding-left: 3rem !important;
						}
					}
				}
			}

			.card {
				background-color: transparent;
				border: none;
				border-radius: 0;
				@include flex-direction($direction: row);
				@include flex-wrap($value: wrap);

				&-image {
					background-color: transparent;
					@include make-col(12);
					@include media-breakpoint-up(sm) {
						@include make-col(7);
					}

					.card-img {
						border-radius: 10px;
					}

					.card-image-caption {
						position: relative !important;
						width: 100px;
						height: 100px;
						overflow: hidden;
						background: $gryish-brown;
						transform: rotate(45deg);
						left: 80%;
						bottom: -35px;

						.card-image-label {
							position: absolute;
							margin: auto;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							background: transparent;
							transform: rotate(315deg);
							color: $white;
							font-size: 0.9375rem;
							font-weight: $font-weight-regular;
							white-space: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							text-align: center;
						}
					}
				}

				&-body {
					background-color: transparent;
					padding: ($grid-gutter-width / 1.5) 0 0;
					flex-direction: row !important;
					align-items: flex-start;

					@include media-breakpoint-up(sm) {
						padding: 0;

					}
					@include make-col(12);
					@include media-breakpoint-up(sm) {
						@include make-col(5);
					}

					.card-caption {
						@include flexbox;
						@include flex-direction($direction: column);
						@include make-col(10);

						.card-title-link {

							@include media-breakpoint-between(sm, md) {
								max-width: 200px;
							}

							&:hover {
								.card-title {
									color: darken($color: $secondary, $amount: 5%);
								}
							}
						}

						.card-title {
							color: $gryish-brown;
							font: {
								family: $font-family-yeseva;
								size: $font-size-base * 1.875;
								weight: $font-weight-regular;
							}
							@include media-breakpoint-up(sm) {
								color: $secondary;
							}
							@include media-breakpoint-up(xl) {
								font-size: $font-size-base * 3.75;
							}
						}

						.card-subtitle {
							order: 1;
							font-size: $font-size-base_sm;
							font-weight: $font-weight-regular;
						}

						.card-description {
							order: 3;

							ul {
								padding-left: 0;

								li {
									@include flexbox;
									@include align-items($value: center);

									&::before {
										content: none;
									}

									.icon {
										i {
											color: $secondary;
											font-size: $h4-font-size;
											font-weight: $font-weight-regular;
										}
									}

									.label {
										font-size: $font-size-base_sm;
									}
								}
							}
						}
					}

					.card-buttons {
						margin: 0;
						@include flexbox;
						@include justify-content($value: flex-end);
						@include make-col(2);

						.card-btn {
							position: relative;
							visibility: hidden;
							transition: 0.3s;

							&:hover {
								&::after {
									background-color: $secondary;
									color: white;
								}
							}

							&::after {
								content: "\F061";
								position: absolute;
								right: 0;
								top: 20px;
								visibility: visible;
								font-family: $font-awesome;
								font-size: $h4-font-size;
								font-weight: $font-weight-bold;
								color: $gryish-brown;
								border: 3px solid $secondary;
								border-radius: 50%;
								padding: $grid-gutter-width / 3;
								width: 34px;
								height: 34px;
								transition: 0.3s;
								@include flexbox;
								@include justify-content($value: center);
								@include align-items($value: center);

								@include media-breakpoint-up(sm) {
									top: 30px;
								}
								@include media-breakpoint-up(xl) {
									color: $secondary;
									width: 45px;
									height: 45px;
									right: 0;
									top: 40px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.gallery-item {
	.gallery-caption {
		display: none;
	}
}

// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
    @extend .clearfix;
}
.search-book .tommy-zeb-wrapper * {
    font-family: $font-family-base !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left {
    background: $primary;
    border-radius: 9px;
    padding: ($grid-gutter-width * 1.67) ($grid-gutter-width / 1.5);
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
    background: $tertiary;
    border-radius: 9px;
    padding: 0;
    @include flexbox;
    @include flex-wrap($value: wrap);

    @include media-breakpoint-up(lg) {
        @include flex-wrap($value: nowrap);
    }
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
    display: none;
}
.search-book .tommy-zeb-wrapper * h2 {
    font-size: 18px;
    font-weight: 700;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
    margin-top: 0 !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
    @extend .btn;
    @extend .btn-primary;
	color: $white;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
    margin-bottom: 10px;
    color: $primary;
    font-size: 20px;
    font-weight: 700;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
    color: $secondary;
    font-weight: 700;
}
.search-book {
    .tommy-zeb-results-label {
        @include media-breakpoint-up(sm) {
            position: absolute;
            top: -60px;
            left: 25px;
            transform: translateY(-50%);
        }
    }
    .tommy-zeb-left {
        h2.ng-binding {
            color: black;
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
        }
    }
    .tommy-zeb-wrapper {
        .tommy-zeb-accommodation-person-category-label,
        .tommy-zeb-period label {
            color: black;
            font-size: $font-size-base_sm;
            font-weight: $font-weight-regular;
        }
        select,
        input {
            border-radius: 9px;
            height: 30px;
            padding: 0 ($grid-gutter-width / 3);
            font-size: $font-size-base_sm;
            font-weight: $font-weight-regular;
            background-color: white;
        }
        .tommy-zeb-left h1,
        .tommy-zeb-person-categories,
        .tommy-zeb-accommodation-objects,
        .tommy-zeb-date {
            border-bottom: 1px solid $cloudy-grey;
        }
        .tommy-zeb-result.ng-scope {
            .tommy-zeb-result-image {
                img {
                    border-radius: 4px;

                    @include media-breakpoint-up(sm) {
                        border-radius: 4px 0 0 4px;
                    }
                }
            }
        }
        .tommy-zeb-result-details {
            padding: ($grid-gutter-width) ($grid-gutter-width) ($grid-gutter-width / 2) !important;

            @include media-breakpoint-up(sm) {
                padding: ($grid-gutter-width) ($grid-gutter-width / 1.2) ($grid-gutter-width / 1.5) ($grid-gutter-width * 1.33) !important;
            }
            .tommy-zeb-result-text {
                font-size: $font-size-base_sm;
                margin-bottom: $grid-gutter-width / 1.5;
            }
        }
        .tommy-zeb-result-label.ng-binding {
            font-family: $font-family-yeseva !important;
            font-size: $h3-font-size;
            font-weight: $font-weight-regular !important;
            color: $secondary;

            @include media-breakpoint-up(lg) {
                font-size: $font-size-base * 1.875;
            }
        }
        .tommy-zeb-result-price.ng-binding {
            font-size: $font-size-base;
        }
    }
}
@media (min-width: 1200px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
        width: 520px;
    }
}
@media (min-width: 980px) and (max-width: 1199px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
        margin-bottom: 15px;
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
        margin-right: 0;
    }
}

> footer > section {
	&.footer-outro {
		padding: ($grid-gutter-width * 2) 0;
		background-color: $bronze-olive;

		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 3) 0;
		}
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						h1 {
							color: $tertiary;
							font-size: $font-size-base * 1.875;
							margin-bottom: $grid-gutter-width / 2;

							@include media-breakpoint-up(sm) {
								font-size: $h2-font-size;
							}
						}
						p {
							color: white;
						}
						.foekje_floris {
							font: {
								size: $h3-font-size;
								family: $font-family-caveat;
							}
						}
					}
					&.two {
						margin-top: $grid-gutter-width / 1.5;
						@include media-breakpoint-up(sm) {
							margin-top: 0;
						}
						p {
							color: white;
							font-size: $font-size-base_sm;
							font-weight: $font-weight-medium;

							&:nth-of-type(2) {
								font-weight: $font-weight-regular;
							}
							&:nth-of-type(3) {
								margin-bottom: $grid-gutter-width / 6;
								text-decoration: underline;
							}
							a {
								color: white;
								font-size: $font-size-base_sm;
								text-decoration: underline;

								&:hover {
									color: $tertiary;
								}
							}
							&:first-of-type {
								color: $tertiary;
								font-size: $h4-font-size;
							}
							&:last-of-type {
								margin-top: $grid-gutter-width * 1.5;
								font-size: $h4-font-size;
							}
						}
						ul.vocabulary-term-list {
							li {
								a {
									font-size: $h3-font-size;
									color: white;
									width: 50px;
									height: 50px;
									border: 3px solid white;
									border-radius: 50%;
									padding: $grid-gutter-width / 6;
									display: block;
									@extend .text-center;

									&:hover {
										background-color: white;
										color: $bronze-olive;
									}
									i.fa-instagram-square::before {
										content: "\f16d" !important;
									}
									span {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.footer-socket {
		padding: ($grid-gutter-width) 0;
		.container-one-column {
			.container-holder {
				.column {
					.footer-link {
						ul {
							li {
								@include media-breakpoint-up(sm) {
									&:not(:last-child) {
										margin-right: $grid-gutter-width * 1.5;
									}
								}
								a {
									color: $gryish-brown;
									text-decoration: none;
									transition: 0.3s;

									font: {
										size: $font-size-base_sm;
										weight: $font-weight-regular;
									}
									&:hover {
										color: $secondary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

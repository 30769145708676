.header {
	position: relative;
	background-image: url("/images/backgroundpaper.png");
	box-shadow: 0 3px 3px rgba(black, 0.1);
	@include media-breakpoint-up(xl) {
		margin-top: -110px;
	}
	.container-one-column {
		.container-holder {
			.column {
				background-image: url("/images/backgroundpaper.png");
				width: 100%;
				height: 100%;
				padding: ($grid-gutter-width / 1.5) ($grid-gutter-width);
				@include media-breakpoint-up(sm) {
					padding: ($grid-gutter-width / 5) ($grid-gutter-width * 2);
				}

				.page-block.menu-toggle {
					order: 0;
					margin: 0;
					flex-grow: 1;

					@include media-breakpoint-up(xl) {
						order: unset;
						flex-grow: 0;
					}
				}
				.page-block.logo {
					order: 4;
					margin-top: 50px;
					@include flexbox;
					@include justify-content($value: space-around);
					@include flex($fg: 1);

					@include media-breakpoint-up(sm) {
						margin-top: 0;
						order: 1;
					}
					a {
						position: relative;
						&::before {
							@include media-breakpoint-down(xs) {
								content: "";
								position: absolute;
								left: 0;
								right: 0;
								width: 316px;
								height: 150px;
								background: url("/images/banner-logo.png");
								background-size: contain;
							}
						}
						img {
							width: 316px;
							height: 150px;
							max-height: 100%;
							@include media-breakpoint-down(xs) {
								opacity: 0;
							}
							@include media-breakpoint-up(sm) {
								width: 192px;
								height: 68px;
							}
						}
					}
					@include media-breakpoint-up(xl) {
						order: unset;
					}
				}
				.page-block.site-switcher {
					margin: 0;
					flex-grow: 1;
					order: 2;
					@include media-breakpoint-up(xl) {
						display: flex;
						justify-content: flex-end;
						margin-left: 25px;
						flex-grow: 0;
						order: unset;
					}
				}
				.page-block.menu.menu {
					order: 2;
					flex-grow: 0;
					&.show {
						padding: ($grid-gutter-width) 0 0;
					}
					@include media-breakpoint-up(sm) {
						order: 3;
					}
					@include media-breakpoint-up(xl) {
						order: unset;
						@include justify-content($value: flex-start);

						.navbar-nav {
							.nav-item {
								&:not(:last-child) {
									.nav-link {
										@include media-breakpoint-up(xxl) {
											padding-right: $grid-gutter-width / 2;
										}
									}
								}
								.nav-link {
									font : {
										size: 0.9375rem;
										weight: $font-weight-medium;
									}
								}
								&.dropdown {

									
								}
							}
							.dropdown-menu {
								border-radius: 6px;
								border: 0;
								background: $primary;
								@include media-breakpoint-down(lg) {
									position: static !important;
									transform: none !important;
									background: transparent;
									border: 0;
								}
								.nav-item {
									.nav-link {
										&:hover {
											background-color: $peach-yellow;
											color: black;
										}
									}
									&.active {
										background-color: $peach-yellow;
										color: black;
									}
								}
							}
							
						}
					}
				}
				#top_menu {
					order: 3;
					@include media-breakpoint-up(sm) {
						order: 4;
					}
					@include media-breakpoint-up(xl) {
						order: unset;
					}
					@include justify-content($value: flex-end);

					ul {
						li {
							a {
								@include media-breakpoint-up(sm) {
									padding-right: $grid-gutter-width / 1.2;
								}
							}
						}
					}
				}
				div.vocabulary-term-list {
					margin-top: $grid-gutter-width / 6;
					order: 1;
					@include media-breakpoint-up(sm) {
						order: 2;
					}
					@include media-breakpoint-up(xl) {
						order: unset;
					}
					ul.vocabulary-term-list {
						margin-bottom: 0;

						li {
							span {
								font-size: 0.9375rem;
								font-weight: $font-weight-bold;
								border-radius: 22px;
								border: 1px solid $quaternary;
								padding: ($grid-gutter-width / 3.157) ($grid-gutter-width / 2);

								@include media-breakpoint-up(sm) {
									padding: ($grid-gutter-width / 2.72) ($grid-gutter-width / 2);
								}
							}
						}
					}
				}
			}
		}
	}
	&.sticky {
		margin-top: 0;
		.container-one-column {
			.container-holder {
				.column {
					background-color: $mercury;

					.page-block.menu {
						flex-grow: 1;
					}
				}
			}
		}
	}
}

&.home {
	.header {
		box-shadow: none;
		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						order: 4;
						opacity: 0;
						@include media-breakpoint-down(xs) {
							opacity: 1;
						}
						@include media-breakpoint-between(sm, lg) {
							display: none;
						}
						@include media-breakpoint-up(xl) {
							order: unset;
						}
					}
				}
			}
		}
		&.sticky {
			.page-block.logo {
				opacity: 1 !important;
			}
		}
	}
}

.btn {
	font-size: $font-size-base_sm !important;
	font-weight: $font-weight-bold;
	height: 48px;
	width: max-content;
	@include flexbox;
	@include align-items($value: center);

	@include media-breakpoint-up(sm) {
		font-size: $h4-font-size !important;
		font-weight: $font-weight-medium;
	}
	// btn-primary
	&.btn-primary {
		&:hover {
			color: white;
			border-color: $secondary;
			background-color: $secondary;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
			color: $secondary;
			border-color: $secondary;
			background-color: white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
			color: white;
		}
	}
}
.bg-dark {
	.btn {
			// btn-secondary
	&.btn-secondary {
		color: white;
		border-color: $secondary;
		background-color: $secondary;
		&:hover {
			color: $secondary;
			border-color: white;
			background-color: white;
		}
	}
		&.btn-outline-primary {
			color: $black;
			&:hover {
			color: white;
			border-color: $primary;
			background-color: $primary;
		}
	}


	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $secondary;
		&:hover {
			color: white;
			border-color: $secondary;
			background-color: $secondary;
		}
	}
	}
}

// btn-back
// =========================================================================
.btn-back {
	position: relative;
	color: black;
	font-size: $font-size-base_sm;
	font-weight: $font-weight-regular;
	text-decoration: none;
	padding-left: $grid-gutter-width * 1.67;
	transition: 0.3s;
	@include flexbox;
	@include align-items($value: center);

	&:hover {
		&::before {
			background-color: $secondary;
			color: white;
		}
	}

	&::before {
		content: "\F060";
		position: absolute;
		left: 0;
		font-family: $font-awesome;
		font-size: $h4-font-size;
		font-weight: $font-weight-bold;
		color: $secondary;
		border: 3px solid $secondary;
		border-radius: 50%;
		padding: $grid-gutter-width / 3;
		width: 34px;
		height: 34px;
		transition: 0.3s;
		@include flexbox;
		@include justify-content($value: center);
		@include align-items($value: center);
	}
}

// btn-down
// =========================================================================
.btn-down {
	@extend .btn;
	@extend .btn-primary;
	display: none;
}

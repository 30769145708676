// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
// @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,400;0,500;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,400;0,500;0,700;1,300&display=swap');

$font-family-bevietnam: 'Be Vietnam Pro', sans-serif;
$font-family-base: $font-family-bevietnam;

@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
$font-family-yeseva: 'Yeseva One', cursive;

@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
$font-family-caveat: 'Caveat', cursive;

$font-awesome: 'Font Awesome 5 Pro';

// build options
$use-sticky-header: true;
$use-html-fontsize-reset: true;


// colors
$primary: #DFB12E;
$secondary: #5A4614;
$tertiary: #ACA883;
$quaternary: #26364D;
$regent-grey: #89939C;
$stone: #B0AC8A;
$dark: #3d4609;
$bronze-olive: #5A4614;
$coral-reef: #C1BD9E;
$gryish-brown: #7B6D4A;
$peach-yellow: #f5deb3;
$mercury: #e8e7e7;
$cloudy-grey: #6b6a6a;

// matrix-colors
$matrix-color-primary: #aca883;
$matrix-color-secondary: #5A4614;
$matrix-color-tertiary: #ACA883;
$matrix-color-quaternary: #26364D;

$link-color: $secondary !default;
$link-color--hover: black !default;

// typography
$font-weight-light: 100 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-base: normal !default;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 5 !default;
$h2-font-size: $font-size-base * 2.5 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.125 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Components
$line-height-base: 1.5 !default;
//

// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-radius: 32px !default;

// Buttons + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.6rem !default;
$input-btn-padding-x: 3rem !default;
$input-btn-font-family: $font-family-base !default;
$input-btn-font-size: $h4-font-size !default;
$input-btn-font-weight: $font-weight-medium !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-border-width: $border-width !default;


// Buttons
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-border-width: $input-btn-border-width !default;
$btn-font-weight: $font-weight-medium !default;

$btn-border-radius: $border-radius !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
box-shadow 0.15s ease-in-out !default;


// carousel
$carousel-min_height: 22vh !default;
$carousel-min_height--lg: 653px !default;
body {
	&.default {
		overflow-x: hidden;
	}
}

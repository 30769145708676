// bg-dark
// =========================================================================
section {
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	padding: ($grid-gutter-width * 2) 0;
	width: 100%;
	height: 100%;
	background: url("/images/backgroundpaper.png");
	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width * 3) 0 ($grid-gutter-width * 2);
	}

	.container-one-column {
		.container-holder {
			.column {
				h1 {
					font-size: $h2-font-size;
					color: $secondary;

					@include media-breakpoint-up(lg) {
						font-size: $font-size-base * 3.75;
					}
				}
				.page-block.subtitle {
					h5 {
						font-size: $h4-font-size;
					}
				}
			}
		}
	}
	.container-two-columns.container-fluid {
		.container-holder {
			.column {
				&.one {
					.page-block {
						@include media-breakpoint-up(xl) {
							max-width: 452px;
						}
						@include media-breakpoint-up(xxl) {
							max-width: 550px;
						}
						h1 {
							font-size: $h2-font-size;
							color: $secondary;

							@include media-breakpoint-up(lg) {
								font-size: $font-size-base * 3.75;
							}
						}
					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}
	.info {
		.page-block.title {
			h1 {
				color: $secondary;
			}
		}
		// accommodation
		.properties {
			padding-left: 0;
			li {
				&::before {
					content: none;
				}
			}
			.icon {
				color: $secondary;
				margin-right: $grid-gutter-width / 3;

				i {
					font-weight: $font-weight-regular;
				}
			}
			.label {
				font-size: $font-size-base_sm;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	&.intro {
		position: relative;
		background: url("/images/backgroundpaper.png");
		width: 100%;
		height: 100%;
		padding: ($grid-gutter-width) 0;
		margin: 0;
		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 3.33) 0 ($grid-gutter-width * 2.83);
		}
		&::before {
			content: "";
			position: absolute;
			left: -39px;
			top: 140px;
			background: url("/images/floralsoutlinelichtblauw-02.png") no-repeat;
			width: 100%;
			height: 100%;
			background-size: contain;

			@include media-breakpoint-up(sm) {
				left: 0;
				top: 24%;
				width: 329px;
				background-size: unset;
			}
			@include media-breakpoint-up(md) {
				left: 10%;
			}
			@include media-breakpoint-up(xxl) {
				left: 25%;
			}
		}
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						.page-block.title {
							h1 {
								font-size: $h2-font-size;
								color: $secondary;
								@extend .text-right;

								@include media-breakpoint-up(lg) {
									font-size: $font-size-base * 3.75;
								}
							}
						}
					}
					&.two {
						.page-block.title {
							h1 {
								font-family: $font-family-base;
								font-size: $h4-font-size;
								font-weight: $font-weight-medium;
								color: $secondary;

								@include media-breakpoint-up(sm) {
									font-size: $h3-font-size;
								}
							}
						}
						.page-block {
							p {
								@include media-breakpoint-down(xs) {
									line-height: 1.8;
								}
							}
						}
						.page-block.link {
							margin-top: $grid-gutter-width / 2;
						}
					}
				}
			}
		}
		.container-one-column.card-block {
			margin-top: $grid-gutter-width * 2;
			.container-holder {
				.column {
					.collection.masonry {
						.card-columns {
							@include media-breakpoint-up(xl) {
								column-count: 3;
							}
							.item {
								&:nth-child(1) {
									@include media-breakpoint-up(lg) {
										transform: translateY(70px);
									}
								}
								&:nth-child(3n-1) {
									@include media-breakpoint-up(lg) {
										transform: translateY(160px);
									}
								}
								.card {
									background: transparent;
									border: 0;
									border-radius: 0;
									@include media-breakpoint-down(xs) {
										margin-bottom: $grid-gutter-width / 3;
									}

									&-image {
										background-color: transparent;
										.card-img {
											min-height: 405px;
											object-fit: cover;
											border-radius: 12px;
										}
									}
									&-body {
										background-color: transparent;
										padding: ($grid-gutter-width / 1.2) 0;
										.card-caption {
											.card-title {
												color: $secondary;
												font-family: $font-family-yeseva;
												font-size: $font-size-base * 1.875;
												font-weight: $font-weight-regular;
												line-height: 1;

												@include media-breakpoint-up(sm) {
													font-size: $h2-font-size;
												}
											}
											.card-description {
												font-size: $font-size-base_sm;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.content {
		position: relative;
		background-color: $tertiary !important;
		background-image: url("/images/backgroundpaper.png");
		width: 100%;
		height: 100%;
		padding: ($grid-gutter-width * 2.5) 0 ($grid-gutter-width * 1.5);
		margin: 0;
		overflow: hidden;
		&::before {
			position: absolute;
			content: "";
			bottom: 50px;
			left: -190px;
			background: url("/images/floralsoutlinebeige05.png") no-repeat;
			background-position: bottom;
			width: 394px;
			height: 100%;
			background-size: contain;

			@include media-breakpoint-up(sm) {
				left: -50px;
				background-size: 100% 65%;
			}
		}
		&::after {
			position: absolute;
			content: "";
			bottom: 300px;
			right: -192px;
			background: url("/images/floralsoutlinebeige05.png") no-repeat;
			background-position: right;
			width: 394px;
			height: 100%;
			background-size: contain;

			@include media-breakpoint-up(sm) {
				right: 0;
				background-size: 100% 65%;
				transform: matrix(1, 0, 0, 1, 45, 0);
			}
		}
		.container-fluid {
			@include media-breakpoint-up(sm) {
				padding: 0 ($grid-gutter-width * 2);
			}
		}
		.container-one-column {
			.container-holder {
				.column {
					h1 {
						color: $gryish-brown;
						margin-bottom: $grid-gutter-width;

						@include media-breakpoint-up(sm) {
							color: $secondary;
						}
					}
					div.page-block.link {
						@extend .mx-auto;

						a {
							@extend .btn;
							@extend .btn-secondary;
							&:hover {
								color: $secondary !important;
								border-color: white !important;
								background-color: white !important;
							}
						}
					}
					.collection.grid {
						.grid-items {
							.item {
								&:last-of-type {
									margin-bottom: $grid-gutter-width;

									@include media-breakpoint-up(sm) {
										margin-bottom: $grid-gutter-width * 3;
									}
								}
							}
						}
					}
				}
			}
		}
		.container-two-columns {
			margin-bottom: $grid-gutter-width;

			@include media-breakpoint-up(sm) {
				margin-bottom: $grid-gutter-width * 3.5;
			}

			p,
			ul li {
				color: black;
			}
		}
	}
	&.info-block {
		position: relative;
		background: url("/images/backgroundpaper.png");
		width: 100%;
		height: 100%;
		padding: ($grid-gutter-width) 0;
		margin: 0;
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 3.33) 0 ($grid-gutter-width * 5.5);
		}
		&::before {
			content: "";
			position: absolute;
			left: -110px;
			top: 165px;
			background: url("/images/floralsoutlinelichtblauw-01.png") no-repeat;
			width: 100%;
			height: 100%;
			background-size: contain;

			@include media-breakpoint-up(sm) {
				left: 0;
				top: 0;
			}
			@include media-breakpoint-up(md) {
				left: 10%;
			}
			@include media-breakpoint-up(xxl) {
				left: 17%;
			}
		}
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						.page-block.title {
							h1 {
								font-size: $h2-font-size;
								color: $secondary;
								@extend .text-right;

								@include media-breakpoint-up(lg) {
									font-size: $font-size-base * 3.75;
								}
							}
						}
					}
					&.two {
						.page-block.title {
							h1 {
								font-family: $font-family-base;
								font-size: $h4-font-size;
								font-weight: $font-weight-medium;
								color: $secondary;

								@include media-breakpoint-up(sm) {
									font-size: $h3-font-size;
								}
							}
						}
						.page-block.link {
							margin-top: $grid-gutter-width / 2;
						}
					}
				}
			}
		}
		.container-one-column {
			margin-top: $grid-gutter-width * 2;
			.container-holder {
				.column {
					.collection.masonry {
						.card-columns {
							@include media-breakpoint-up(xl) {
								column-count: 3;
							}
							.item {
								&:nth-child(1) {
									@include media-breakpoint-up(lg) {
										transform: translateY(70px);
									}
								}
								&:nth-child(3n-1) {
									@include media-breakpoint-up(lg) {
										transform: translateY(160px);
									}
								}
								.card {
									background: transparent;
									border: 0;
									border-radius: 0;

									&-image {
										background-color: transparent;
										.card-img {
											min-height: 405px;
											object-fit: cover;
											border-radius: 12px;
										}
									}
									&-body {
										background-color: transparent;
										padding: ($grid-gutter-width / 1.2) 0;
										.card-caption {
											.card-title {
												color: $secondary;
												font-family: $font-family-yeseva;
												font-size: $font-size-base * 1.875;
												font-weight: $font-weight-regular;
												line-height: 1;

												@include media-breakpoint-up(sm) {
													font-size: $h2-font-size;
												}
											}
											.card-description {
												font-size: $font-size-base_sm;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	// intro/outro
	.intro,
	.outro {
		max-width: 750px;
		.container-holder {
			text-align: center;
		}
	}
	.intro {
		.container-holder {
			margin-bottom: 30px;
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.outro {
		.container-holder {
			margin-top: 30px;
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	position: relative;
	background-color: $tertiary !important;
	background-image: url("/images/backgroundpaper.png");
	width: 100%;
	height: 100%;
	padding: ($grid-gutter-width * 3.5) 0 ($grid-gutter-width * 3);
	overflow: hidden;

	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width * 3.5) 0 ($grid-gutter-width * 6);
	}
	.container {
		.container-holder {
			.column {
				z-index: 1;
			}
		}
	}
	.container-one-column.card-block {
		margin-top: $grid-gutter-width * 2;
		.container-holder {
			.column {
				.collection.grid {
					.grid-items {
						.item {
							@include media-breakpoint-up(sm) {
								@include make-col(6);
							}
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
							margin-bottom: $grid-gutter-width;
							&:nth-child(odd),
							&:nth-child(even) {
								.card-body {
									padding: 15px 0 !important;
								}
							}
							&:nth-child(3n-2) {
								@include media-breakpoint-up(lg) {
									transform: translateY(70px);
								}
							}
							&:nth-child(3n-1) {
								@include media-breakpoint-up(lg) {
									transform: translateY(160px);
								}
							}
							.card {
								background: transparent;
								border: 0;
								border-radius: 0;
								&-image {
									order: 1;
									background-color: transparent;
									@include make-col(12);

									.card-img {
										min-height: 405px;
										object-fit: cover;
										border-radius: 12px;
									}
								}
								&-body {
									order: 2;
									@include make-col(12);
									background-color: transparent;
									padding: ($grid-gutter-width / 1.2) 0 !important;
									.card-caption {
										.card-title-link {
											max-width: 280px;
										}
										.card-title {
											color: $secondary;
											font-family: $font-family-yeseva;
											font-size: $font-size-base * 1.875;
											font-weight: $font-weight-regular;
											line-height: 1;

											@include media-breakpoint-up(lg) {
												font-size: $h2-font-size;
											}
										}
										.card-description {
											font-size: $font-size-base_sm;
										}
									}
									.card-buttons {
										.card-btn {
											&::after {
												right: 10px;
												width: 34px;
												height: 34px;
												transform: translate(45px, -150px);

												@include media-breakpoint-up(lg) {
													transform: translate(45px, -185px);
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	position: relative;
	padding: 0 0 ($grid-gutter-width * 2);
	width: 100%;
	height: 100%;
	background: url("/images/backgroundpaper.png");

	@include media-breakpoint-up(sm) {
		padding: 0 0 ($grid-gutter-width * 3);
	}
	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					order: 2;
					padding-left: 1rem !important;
					padding-right: 0 !important;
					@include media-breakpoint-up(sm) {
						padding-left: 1.5rem !important;
					}
					@include media-breakpoint-up(lg) {
						padding-left: 3rem !important;
					}
				}
				&.two {
					order: 1;
					padding-left: 0 !important;
				}
			}
		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	background-color: $tertiary;
	padding: ($grid-gutter-width * 2) 0;
	overflow: hidden;

	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width * 3) 0;
	}
	h2 {
		margin-bottom: 30px;
		text-align: center;
	}
}

//gallery-section
.gallery-section {
	position: relative;
	padding: ($grid-gutter-width * 2) 0;
	width: 100%;
	height: 100%;
	background: url("/images/backgroundpaper.png");
	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width * 2) 0 ($grid-gutter-width * 3);
	}

	.container-one-column {
		.container-holder {
			.column {
				.gallery-horizontal {
					.gallery-label {
						position: absolute;
						width: 100px;
						height: 100px;
						right: 70px;
						top: -50px;
						color: white;
						font-size: 0.9375rem;
						font-weight: $font-weight-regular;
						white-space: normal;
						display: flex;
						z-index: 1;
						justify-content: center;
						align-items: center;

						&::after {
							position: absolute;
							content: "";
							top: 0;
							left: 0;
							width: 100px;
							height: 100px;
							background: $tertiary;
							transform: rotate(45deg);
							z-index: -1;
						}
					}
				}
			}
		}
	}
}

&.accommodation-search-book,
&.accommodation-book {	
	.main {
		> section {
			width: 100%;
			height: 100%;
			background: url("/images/backgroundpaper.png");
		}
	}
	.lead-section {
		.container-one-column {
			.container-holder {
				.column {
					.page-block {
						h1 {
							font-size: $h3-font-size;

							@include media-breakpoint-up(lg) {
								font-size: $h2-font-size;
							}
						}
					}
				}
			}
		}
	}
	.content-section {
		margin: 0;
		padding-bottom: $grid-gutter-width * 2;

		@include media-breakpoint-up(sm) {
			padding-bottom: $grid-gutter-width * 3;
		}
	}
}

&.accommodation-book {
	.main {
		.content-section {
			.tbs-legenda {
				ul {
					li {
						.tbs-translate {
							color: black !important;
						}
					}
				}
			}
		}
	}
}

&.accommodation-category-overview {
	.lead-section {
		&::after {
			position: absolute;
			content: "";
			bottom: -65px;
			right: 100px;
			background: url("/images/floralsoutlinelichtblauw-04.png") no-repeat;
			background-position: top;
			width: 212px;
			height: 100%;
			background-size: cover;
			z-index: -1;

			@include media-breakpoint-up(sm) {
				background-size: 96% 200%;
			}
		}
	}
}
&.bundle-category {
	.lead-section {
		overflow: hidden;
		&::after {
			position: absolute;
			content: "";
			bottom: 0;
			left: calc(100% - 53%);
			background: url("/images/floralsoutlinelichtblauw-02.png") no-repeat;
			background-position: top;
			width: 100%;
			height: 100%;
			background-size: cover;
			@include media-breakpoint-up(sm) {
				background-size: 20% 190%;
			}
		}
	}
	.bundle-overview-section {
		padding: ($grid-gutter-width * 3.5) 0 ($grid-gutter-width * 3);

		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width) 0 ($grid-gutter-width * 6);
		}
		&::before {
			position: absolute;
			content: "";
			bottom: 0;
			left: -190px;
			background: url("/images/floralsoutlinebeige05.png") no-repeat;
			background-position: bottom;
			width: 385px;
			height: 100%;
			background-size: contain;
			@include media-breakpoint-up(sm) {
				left: -150px;
				bottom: -500px;
				background-size: 100% 50%;
			}
		}
	}
}

&.bundle-overview {
	.bundle-overview-section {
		&::before {
			position: absolute;
			content: "";
			bottom: 0;
			left: -190px;
			background: url("/images/floralsoutlinebeige-06.png") no-repeat;
			background-position: left;
			width: 385px;
			height: 100%;
			background-size: contain;
			@include media-breakpoint-up(sm) {
				left: -150px;
				background-size: unset;
			}
		}
		&::after {
			position: absolute;
			content: "";
			bottom: 0;
			right: -190px;
			background: url("/images/floralsoutlinebeige05.png") no-repeat;
			background-position: right;
			width: 394px;
			height: 100%;
			background-size: contain;

			@include media-breakpoint-up(sm) {
				right: 0;
				background-size: unset;
			}
		}
	}
}

&.bundle-detail {
	.bundle-detail-section {
		overflow: hidden;
		&::after {
			position: absolute;
			content: "";
			bottom: -100px;
			right: 100px;
			background: url("/images/floralsoutlinelichtblauw-03.png") no-repeat;
			background-position: top;
			width: 212px;
			height: 100%;
			background-size: cover;
			z-index: -1;
			transform: scaleX(-1);

			@include media-breakpoint-up(sm) {
				background-size: 90% 85%;
			}
		}
	}
}

&.contact {
	.content-section {
		padding: ($grid-gutter-width * 2) 0;

		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 4) 0;
		}
		margin: 0;
		width: 100%;
		height: 100%;
		background: url("/images/backgroundpaper.png");

		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						order: 1;

						@include media-breakpoint-up(sm) {
							order: 2;
							padding-left: 1.5rem !important;
							padding-right: 0 !important;
						}
						@include media-breakpoint-up(lg) {
							padding-left: 3rem !important;
						}
						.page-block.logo {
							margin-bottom: $grid-gutter-width;
							img {
								width: 342px;
							}
						}
						.page-block {
							p {
								&:nth-of-type(2) {
									line-height: 1.8;
									margin-bottom: $grid-gutter-width * 1.67;

									a {
										position: relative;
										font-size: $h4-font-size;
										padding-left: $grid-gutter-width / 1.2;
										color: black;
										&:hover {
											color: $secondary;
										}
										&::before {
											position: absolute;
											left: 0;
											top: 0;
											font-family: $font-awesome;
											font-size: $font-size-base_sm;
											font-weight: $font-weight-bold;
										}
										&:first-of-type {
											&::before {
												content: "\f879";
											}
										}
										&:last-of-type {
											&::before {
												content: "\F0E0";
											}
										}
									}
								}
								&:last-of-type {
									margin: ($grid-gutter-width / 1.2) 0;
									font-weight: $font-weight-bold;
								}
							}
						}
					}
					&.two {
						order: 2;

						@include media-breakpoint-up(sm) {
							order: 1;
							padding-right: 1.5rem !important;
							padding-left: 0 !important;
						}
						@include media-breakpoint-up(lg) {
							padding-right: 3rem !important;
						}
						.page-block {
							h3 {
								font-family: $font-family-yeseva;
								font-size: $h3-font-size;
								font-weight: $font-weight-regular;
								margin-bottom: $grid-gutter-width;

								@include media-breakpoint-up(lg) {
									font-size: $h2-font-size;
								}
							}
						}
						.form {
							border: none;
							border-radius: 9px;
							background-color: $primary;

							&-group {
								.form-control {
									color: black;
									border-radius: 9px;
									height: 30px;
									font-size: $font-size-base_sm;
									padding: ($grid-gutter-width / 6) ($grid-gutter-width / 3);
								}
								.form-control-label {
									font-size: $font-size-base_sm;
								}
								textarea {
									height: auto !important;
								}
								.form-check-label {
									&,
									a {
										font-size: $font-size-base_sm;
									}
								}
								button {
									width: 100%;
									margin: ($grid-gutter-width * 1.5) 0;
									@extend .btn-secondary;
									@extend .text-center;
									@include justify-content($value: center);
								}
							}
						}
					}
				}
			}
		}
	}
}

.main {
	.lead-section + .gallery-section {
		padding-top: 0;
	}
}
&.home {
	.lead-section {
		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 3) 0 ($grid-gutter-width);
		}
	}
}
&.theme-demo {
	.lead-section {
		background: transparent;
		.container-one-column {
			.container-holder {
				.column {
					@include flexbox;
					h1,
					h5 {
						color: $secondary;
					}
					h1 {
						order: 1;
						font: {
							size: $h2-font-size;
							weight: $font-weight-regular;
							family: $font-family-yeseva;
						}
					}
					h5 {
						order: 2;
						font: {
							size: $h3-font-size;
							weight: $font-weight-medium;
						}
					}
					p {
						order: 3;
					}
				}
			}
		}
		&.bg-dark {
			background-color: $stone !important;
			padding-top: $grid-gutter-width * 3;
			margin-top: $grid-gutter-width * 3;

			.container-one-column {
				.container-holder {
					.column {
						h1,
						h5 {
							color: white;
						}
					}
				}
			}
		}
	}
	.content-section {
		.container-two-columns {
			.container-holder {
				.column {
					p {
						&:first-of-type {
							color: $secondary;
							font: {
								size: $h3-font-size;
								weight: $font-weight-medium;
							}
							strong {
								font-weight: $font-weight-medium;
							}
						}
						&:last-of-type {
							font-size: $font-size-base_sm;
						}
						em {
							font-style: normal;
						}
					}
				}
			}
		}
		&.bg-dark {
			background-color: $stone !important;
			padding-bottom: $grid-gutter-width * 3;

			.container-two-columns {
				.container-holder {
					.column {
						p {
							&:first-of-type {
								color: white;
							}
						}
					}
				}
			}
		}
	}
}

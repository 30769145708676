.mini-sab {
	position: absolute;
	left: 0;
	right: 0;
	@include media-breakpoint-down(xs) {
		position: relative;
		background-image: url("/images/backgroundpaper.png");
		width: 100%;
		height: 100%;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		.container-holder {
			width: 90%;
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 18px;
			background: $primary;
			margin-top: -115px;

			@include media-breakpoint-up(sm) {
				border-radius: 47px;
				width: 100%;
				margin-top: 0;
				transform: translateY(-125px);
			}
			
			// below eyecatcher
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}
			@include media-breakpoint-up(lg) {
				transform: translateY(-65px);
			}
		}
	}
}
&.home {
	.mini-sab {
		position: absolute !important;
		background: transparent;
		.container {
			.container-holder {
				transform: translateY(0);
				margin-top: 0;
				@include media-breakpoint-up(sm) {
					transform: translateY(230px);
				}
			}
		}
	}
}

// new base file - january 2022

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
  background-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info:hover {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success,.tbs-btn-default {
  @extend .btn;
  color: white !important;
  border-color: $secondary;
  background-color: $secondary;
  box-shadow: none !important;
  border-radius: $border-radius !important;
  padding: $input-btn-padding-y $input-btn-padding-x  !important;

  &::after,&::before {
    content: none;
  }
  &:hover {
    color: $secondary !important;
    border-color: white;
    background-color: white;
  }
}

#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
  transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  background-color: $primary !important;
}

// include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
  padding: 0;
}
#TommyBookingSupport .tbs-navbar-collapse {
  padding: 0;
}
#TommyBookingSupport .tbs-navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

#TommyBookingSupport .tbs-navbar {
  min-height: auto;
  overflow: hidden;
}
#TommyBookingSupport * a {
  text-decoration: none;
}
#TommyBookingSupport .tbs-legenda {
  background: none;
  border: none;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
  padding: 20px 0;
  margin: 20px 0;
  background-color: #f8f8f8; // custom
  border-radius: 5px;
}
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
  margin: 33px 0;
  padding: 20px;
}
#TommyBookingSupport .tbs_overlay-mask {
  border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
  margin: 20px 0;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
  cursor: pointer;
  transition: all 0.5s ease;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
  opacity: 0.8;
}
#TommyBookingSupport .tbs-calendar table > thead > tr > th {
  border: none;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  color: #fff;
  background-color: #105251;
}

#TommyBookingSupport .tbs-row h2 {
  margin-bottom: 0;
}
#TommyBookingSupport .tbs-btn-block + .tbs-btn-block {
  margin: 0;
}
#TommyBookingSupport .tbs_overzicht .tbs-row h2 {
  margin-bottom: 15px;
}

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row {
  margin-top: 15px;
}

#TommyBookingSupport .tbs-alert-danger {
  color: #e91e63;
  background-color: rgba(233, 30, 99, 0.09);
  border: none;
  margin: 0 15px 15px;
  padding: 10px 35px 10px 10px;
}
#TommyBookingSupport .tbs-has-error .tbs-form-control {
  border-color: rgb(244, 67, 54);
}

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row {
  margin-bottom: 15px;
}
#TommyBookingSupport .tbs-label {
  font-size: 100%;
  margin-right: 5px;
}
#TommyBookingSupport .tbs_kassabon .tbs-hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate {
  font-weight: 400;
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount {
  font-size: 32px;
  line-height: 50px;
}
#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row {
  margin: 0 0 15px 0;
}

#TommyBookingSupport .tbs-bind.tbs-btn {
  background-color: transparent;
  border-color: $secondary !important;
  color: $secondary !important;
  width: 45px;
  height: 45px;
  border: 3px solid;
  border-radius: 50%;
  padding: ($grid-gutter-width / 3) ($grid-gutter-width / 2) !important;
  i {
    font-family: $font-awesome;
    font-size: $h4-font-size;
    color: $secondary !important;
    @include flexbox;
    @include justify-content($value: center);

    &::before,
    &::after {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }
  }
  .tbs-glyphicon-chevron-right {
    &::before {
      content: "\F061" !important;
    }
  }
  .tbs-glyphicon-chevron-left {
    &::before {
      content: "\F060" !important;
    }
  }
  &:hover {
    background-color: $secondary;
    border-color: $secondary;

    i {
      color: white !important;
    }
  }
}

#TommyBookingSupport {
  .tbs-page {
    h2 {
      font-size: $h3-font-size;
      margin-bottom: $grid-gutter-width / 1.5;
    }
  }
  .tbs-container-fluid {
    .tbs-row {
      div.tbs-col-lg-8 {
        padding: ($grid-gutter-width * 1.33) 0 0;
        border-radius: 0px 0px 6px 6px;
        position: relative;

        .tbs-container-fluid {
          .tbs-navbar-collapse {
            border: none;
            .tbs-navbar-nav {
              display: flex;
              justify-content: center;
              align-items: center;
              float: none;
              background-color: white;
              width: max-content;
              border-radius: 9px;
              padding-left: 0;
              margin-bottom: 0;
              li {
                margin-bottom: 0;
                &::before {
                  content: none;
                }
                a {
                  display: flex;
                  color: $gryish-brown;
                  padding: 10px;
                  background-color: transparent;
                  font-size: $font-size-base_sm;
                  font-weight: $font-weight-bold;
                  i {
                    margin-right: 5px;
                  }
                }
                &.tbs-active,
                &:hover {
                  a {
                    background-color: white;
                    color: $secondary;
                  }
                }
                &:not(:nth-last-child(2)) {
                  &::after {
                    content: "";
                    border-right: 2px solid $gryish-brown;
                    height: 80%;
                    width: 2px;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
        .tbs-page-verblijf {
          .tbs-container-fluid {
            .tbs_persoonscategorien,
            .tbs_accommodaties {
              background: transparent;
              label.tbs-translate {
                font-size: $font-size-base_sm;
                font-weight: $font-weight-regular;
                color: black;
                margin-bottom: $grid-gutter-width;
              }
              div.tbs-translate {
                font-size: $font-size-base_sm;
                font-weight: $font-weight-regular;
              }
              .tbs-form-control {
                height: 24px;
                border-radius: 9px;
                color: $black;
                font-size: $font-size-base_sm;
              }
              .tbs-row {
                margin-bottom: 10px;
              }
            }
            .tbs_accommodaties {
              .tbs-form-control {
                height: 36px;
                font-weight: $font-weight-regular;
              }
            }
            .tbs_kalenders {
              background-color: transparent;
              div {
                .tbs-row {
                  &:first-of-type {
                    @include flexbox;
                    @include align-items($value: center);
                  }
                  .tbs-col-xs-6.tbs-col-md-8 {
                    padding: 0;

                    .tbs-form-control {
                      color: black;
                      height: 36px;
                      border-radius: 9px;
                      @extend .text-center;
                    }
                  }
                  .tbs-calendar {
                    .tbs-day {
                      border-radius: 4px;
                      border: none;
                    }
                    .tbs-table {
                      .tbs-month,
                      .tbs-translate {
                        font-size: $font-size-base_sm;
                        font-weight: $font-weight-regular;
                        color: black;
                        opacity: 1;
                      }
                      thead {
                        tr {
                          &:first-of-type {
                            th {
                              border: none;
                            }
                          }
                          th {
                            border-width: 1px 0 1px 0;
                            border-color: black;
                            border-style: solid;
                          }
                        }
                      }
                      tbody {
                        .tbs-day div {
                          color: $black;
                          font-size: $font-size-base_sm;
                        }
                        .tbs-day.tbs-verleden {
                          background-color: white !important;
                          border: none;
                        }
                      }
                    }
                  }
                  .tbs-legenda {
                    ul {
                      li {
                        .tbs-day {
                          width: 17px;
                          height: 17px;
                          &::after {
                            content: none;
                          }
                        }
                        .tbs-translate {
                          font-size: $font-size-base_sm;
                          color: white;
                        }
                      }
                    }
                  }
                }
              }
              .tbs-bind {
                box-shadow: $box-shadow;
                i {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
  .tbs-btn-success {
    color: black;
    font-weight: $font-weight-medium;
    box-shadow: $box-shadow;
    border-radius: 18px;
    padding: 7px 30px;
  }
  .tbs_kassabon {
    background-color: transparent !important;
    .tbs-text-muted {
      color: rgba($color: black, $alpha: 0.5);
    }
    .tbs-totaal {
      color: $secondary;
      font: {
        size: $font-size-base * 1.875 !important;
        weight: $font-weight-regular;
      }
    }
    .tbs_totaal_overzicht {
      .tbs-btn-success {
        width: 100% !important;
        justify-content: center;
      }
    }
    .tbs_kassabon_overzicht {
      .tbs-col-xs-12 {
        .tbs-row {
          .tbs-col-xs-12 {
            padding: 0;
            h2 {
              color: $secondary;
              font-family: $font-family-base;
              margin-top: 20px;
              @extend .text-left;

              span {
                font-size: $h3-font-size !important;
                font-weight: $font-weight-medium !important;
              }
            }
          }
          .tbs-col-xs-8 {
            strong {
              margin-left: -15px;
            }
          }
          .tbs-translate,
          .tbs-col-xs-9 {
            padding: 0;
            font-size: $font-size-base_sm;
            font-weight: $font-weight-regular;
          }
        }
        .tbs-specificaties {
          .tab-row {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .tbs-hr {
      border-top: 2px solid #898769;
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      &::before {
        content: none;
      }
    }
  }
}
/* resonsive mobile */
@media (max-width: 767px) {
  #TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
  #TommyBookingSupport .tbs-calendarNext span.tbs-translate {
    display: none;
  }
}

// headings
h1 {
	font-size: $h2-font-size;

	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
	}
}
h1,
h2 {
	font-family: $font-family-yeseva;
	font-weight: $font-weight-regular;
}
h2,
h3 {
	color: $secondary;
}
h3,
h4,
h5,
h6 {
	font-family: $font-family-base;
	font-weight: $font-weight-medium;
}
p {
	font-size: $font-size-base;
	font-weight: $font-weight-regular;
    line-height: 1.6;
	
	@include media-breakpoint-up(sm) {
		font-size: $h4-font-size;
	}
}
a {
	font-size: $font-size-base;

	&:hover {
		color: darken($color: $secondary, $amount: 5%);
	}
}
.bg-dark {
	a {
		color: white;

		&:hover {
			color: $secondary;
		}
	}
}
// img & iframe
img {
	max-width: 100%;
	height: auto;
}
iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.main {
	.container-fluid {
		padding: 0 ($grid-gutter-width / 2);
		@include media-breakpoint-up(sm) {
			padding: 0 ($grid-gutter-width * 2);
		}
	}
	.container {
		.column {
			z-index: 1;
		}
	}
}

//gallery 
.gallery-item {
    .gallery-link {
        .gallery-img,.gallery-caption {
            border-radius: 9px;
         }
    }
}
.dropdown-toggle {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-breakpoint-down(lg) {
		&[aria-expanded="true"] {
			&::after {
				content: "\f068" !important;
			}
		}
	}
	&::after {
		@include media-breakpoint-down(lg) {
			margin-right: 3px;
			content: "\F067" !important;
			font-family: $font-awesome;
			font-size: 0.813rem;
			font-weight: 700;
			border: none !important;
		}
	}
}
.dropdown-menu {
	&.show {
		border-radius: 6px;
		border: 0;
		background: $primary;
		@include media-breakpoint-down(lg) {
			position: static !important;
			transform: none !important;
			background: transparent;
			border: 0;
		}
		.nav-item {
			.nav-link {
				&:hover {
					background-color: $peach-yellow;
					color: black;
				}
			}
			&.active {
				background-color: $peach-yellow;
				color: black;
			}
		}
	}
}
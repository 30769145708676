// old base file

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.mini-sab .tommy-zeb-wrapper {
	float: none;
	width: auto;
}

.mini-sab .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
	font-size: 14px;
	float: none !important;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
	flex-wrap: wrap;
	float: none;
	background: none;
	color: $black;
	padding: 0 !important;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left label {
	color: $black;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
	justify-content: center !important;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
	margin-right: 15px;
	padding-right: 0;
	border-right: none;
}

.mini-sab .tommy-zeb-wrapper-mini h2.ng-binding {
	display: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label {
	display: none;
}

/* Accommodatietype */
//.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
//	display: block !important;
//}

.mini-sab
.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
.tommy-zeb-accommodation-types
.tommy-zeb-accommodation-type-choice::before {
	display: block;
	content: "Type accommodatie";
	line-height: 30px;
	font-size: $font-size-base_sm;
	font-weight: 400;
}

.de
.mini-sab.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
.tommy-zeb-accommodation-types
.tommy-zeb-accommodation-type-choice::before {
	content: "Unterkunft";
}

.en
.mini-sab.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
.tommy-zeb-accommodation-types
.tommy-zeb-accommodation-type-choice::before {
	content: "Accommodation";
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select {
	max-width: 200px;
	min-width: 150px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category select {
	width: 120px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input {
	line-height: 20px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select {
	padding: 5px;
	margin: 0;
	width: 100%;
	@include media-breakpoint-down(xs) {
		font-size: $font-size-base_xxs;
	}
}

.mini-sab .tommy-zeb-period {
	display: flex !important;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin {
	margin-right: 15px;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label {
	float: none !important;
	display: block;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label {
	margin-bottom: 0 !important;
	line-height: 30px;
	display: block;
	font-size: $font-size-base_sm;
	font-weight: 400;
}

.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
	padding: 0;
	float: left;
	margin-bottom: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
	border-right: none;
	padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 4px;
	padding: 6px 10px;
	height: 36px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select {
	padding-top: 4px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	width: 130px;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
	min-width: 120px;
	margin: 0 !important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update:hover {
	border-color: $secondary;
	background-color: $secondary;
}

/* collapse functionaliteit */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding {
	display: block;
	margin: 0;
	color: $black;
	font-size: $font-size-base_sm;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
	text-transform: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding::after {
	display: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
	display: flex;
	min-width: auto;
}

.mini-sab
.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
.tommy-zeb-person-categories-collapse
.tommy-zeb-travelcompany-total {
	border: 0;
	padding: 6px;
	width: 50px !important;
	height: 36px;
	margin-top: 0 !important;
	margin-left: 15px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list {
	z-index: 99;
	margin-top: 37px;
	background-color: #fff;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	color: #000;
	width: 100%;
	max-width: 100%;
	min-width: 260px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list .ng-scope {
	padding: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category {
	flex-wrap: nowrap;
	margin: 5px 0;
}

.mini-sab
.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
.tommy-zeb-accommodation-person-category
.tommy-zeb-accommodation-person-category-label {
	flex: 1 1 auto;
}

.mini-sab
.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
.tommy-zeb-accommodation-person-category
.tommy-zeb-accommodation-person-category-choice {
	flex: 0 0 85px;
	align-self: center;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-choice .ng-binding {
	min-width: 20px;
	line-height: 22px;
	text-align: center;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range {
	background-color: $primary;
	color: #fff;
	line-height: 22px;
	transition: all 0.5s ease;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range:hover {
	background-color: $secondary;
	color: #fff;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range span {
	line-height: 24px;
	top: 0 !important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories {
	height: 36px;
	padding: 7px 0;
	color: #000;
	font-weight: 400;
	text-transform: none;
	background-color: transparent;
	border: 0;
}

.mini-sab
.tommy-zeb-wrapper.tommy-zeb-wrapper-mini
tommy-person-category-mini
.tommy-zeb-person-categories-collapse
button.tommy-zeb-btn {
	margin-top: 0 !important;
	text-align: left;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories::after {
	font-family: "Font Awesome 5 Pro";
	content: "\f107";
	margin-left: 15px;
}

.mini-sab .mini-search-book {
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
		display: flex;
		min-width: auto;
		border-bottom: 1px solid black;
		width: 100%;
	}

	.tommy-zeb-wrapper {
		.tommy-zeb-left {
			input,
			select {
				font-size: $font-size-base;
				font-weight: $font-weight-medium;
				padding-left: 0;
				background: transparent;
				border-width: 0 0 1px 0;
				border-bottom-color: black;
				border-radius: 0;

				@include media-breakpoint-down(xs) {
					font-size: $font-size-base_xxs;
				}
			}
		}
	}

	.tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories,
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
		font-size: $font-size-base_xxs;
		font-weight: $font-weight-medium;

		@include media-breakpoint-up(sm) {
			font-size: $font-size-base;
		}
	}

	.tommy-zeb-date-begin-choice,
	.tommy-zeb-date-end-choice {
		position: relative;
		display: inline-block;

		&::after {
			content: "\F061";
			position: absolute;
			right: 2px;
			top: 0;
			font-family: $font-awesome;
			font-size: $font-size-base_sm;
			font-weight: $font-weight-bold;
		}
	}

	.tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
		@include flexbox;
		@include justify-content($value: center);
		@include align-items($value: center);
		position: relative;
		padding: 0 !important;
		visibility: hidden;
		transition: 0.3s;

		&:hover {
			&::after {
				background-color: black;
				color: white;
			}
		}

		&::after {
			content: "\F061";
			visibility: visible;
			font-family: $font-awesome;
			font-size: $h4-font-size;
			font-weight: 700;
			color: black;
			z-index: 1;
			border: 3px solid black;
			padding: 10px;
			width: 45px;
			height: 45px;
			border-radius: 50%;
			@include flexbox;
			@include justify-content($value: center);
			@include align-items($value: center);
			transition: 0.3s;
			@include media-breakpoint-down(sm) {
				position: absolute;
				right: -90px;
				top: -62px;
			}
		}
	}

	.ng-isolate-scope {
		@include flexbox;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
		width: auto;
	}
}

@media (max-width: 767px) {
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
		display: block;
	}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
		border-bottom: none;
		padding: 0;
		margin: 0;
		width: 70%;
	}
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
	.mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope {
		float: none;
		clear: both;
		width: 70%;
	}
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
		margin: 0 !important;
	}
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
		width: auto;
	}
	.mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update {
		display: block;
	}

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select {
		max-width: none;
	}
	.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
	.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
		display: block !important;
		width: 100% !important;
	}

	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
		margin-top: 15px !important;
	}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
		margin-right: 0;
	}
}

.impression-section {
    background: url("/images/backgroundpaper.png");
    width: 100%;
    height: 100%;
    padding: ($grid-gutter-width * 2.5) ($grid-gutter-width / 2) 0;

    @include media-breakpoint-up(sm) {
        padding: ($grid-gutter-width * 3) ($grid-gutter-width / 2) 0;
    }

    // margin: ($grid-gutter-width * 3) 0;

    @include media-breakpoint-up(sm) {
        padding: ($grid-gutter-width * 4) ($grid-gutter-width * 2) 0;
    }
    .container-one-column {
        .container-holder {
            .column {
                @extend .eyecatcher;
                background: none;
                padding: 0;
                .owl-carousel {
                    min-height: $carousel-min_height--lg;

                    .owl-stage-outer {
                        .owl-stage {
                            .owl-item {
                                .item {
                                    min-height: $carousel-min_height--lg;
                                    border-radius: 12px;
                                }
                            }
                        }
                    }
                    .owl-dots {
                        right: auto;
                        bottom: 40px;
                        left: 50px;
                    }
                }
            }
        }
    }
}
